import 'bootstrap/dist/css/bootstrap.css'

import React from 'react';
import {
    Row, Col, Container
} from 'reactstrap';

import '../icofont.min.css';

// Web theme color root css
import '../components/colors.css';

import '../Terms.css';
import Tdscontact from "../components/Tdscontact";
import Tdsfooter from "../components/Tdsfooter";
import Tdsheader from "../components/Tdsheader";

export default function Terms() {
    return (
        <div className={"terms"}>
            {/* Navbar  */}
            <Tdsheader active={"terms"} isHome={false} />

            <div className={"section"} id={"terms"}>
                <h1 className={"heading mt-5 mb-1 ml-3 ml-sm-5"}>Terms & Conditions</h1>

                <Container className={"mt-5"}>
                    <Row className={"mt-2 ml-2 mr-2"}>
                        <Col xs={12}>
                            <p className="text-justify">
                                Whereas the customer desires to restore some of their own industrial hydraulic appliances and
                                whereas Opal Hydraulic is willing to accept such orders in accordance with the terms and conditions hereof.
                                <br/>
                                Now, therefore for good and valuable consideration, the receipt and sufficiency of which is hereby
                                acknowledged, the parties hereto agree as follows:
                                <br/>
                            </p>
                        </Col>
                    </Row>
                    <Row className={"mt-2 ml-2 mr-2"}>
                        <Col xs={12}>
                            <h4>Terms</h4>
                            <p className="text-justify">
                                <ol>
                                    <li>
                                        1. Restored equipment has a three-month warranty. The warranty will be offered only for
                                        workmanship, therefore, if previous defects of the repaired item have not been repaired,
                                        Opal Hydraulic agrees to re-repair the defective item free of charge.
                                    </li>
                                    <br/>
                                    <li>
                                        1-1. Damages caused by improper use and operation of repaired equipment will void the warranty.
                                    </li>
                                    <br/>
                                    <li>
                                        2. In the event of discovering major damage to internal components of the repairable equipment
                                        (which will be discovered during the restoration process), Opal Hydraulic will not be responsible
                                        for repairing the equipment and will be required to return the equipment free of charge.
                                    </li>
                                    <br/>
                                    <li>
                                        2-1. Unless otherwise agreed between two parties, Opal Hydraulic will be able to calculate a
                                        new quote for repairing the discovered defects.
                                    </li>
                                    <br/>
                                    <li>
                                        3. Users are required to use hydraulic equipment in a principled manner. Therefore,
                                        accidents caused by any incorrect use that is in contrary to instructions published by
                                        manufacturers of the repaired equipment and to the safety warning explained below, is the
                                        customer’s responsibility only and the customer is to be taken accountable for.
                                    </li>
                                    <br/>
                                    <li className="text-danger">
                                        <strong>Safety warning:</strong> It is extremely dangerous for any user to place different parts of their
                                        body under or between heavy objects that have been moved or lifted by hydraulic equipment.
                                        Therefore, after using the hydraulic equipment to move or to lift an object, users are
                                        obliged to completely and safely stabilise the object, release the hydraulic jack such
                                        that it is not holding on to any parts of the above-mentioned object, and then to start
                                        working on the object.
                                    </li>
                                    <br/>
                                    <li>
                                        4. If during the restoration process of the defective equipment, our experts discover new
                                        defects that will potentially affect the previously given quote, a new quote will be calculated
                                        and will be provided to the customer. The continuation of the repair process is subject
                                        to the customer’s agreement with the new quote. If not agreed, Opal Hydraulic is required
                                        to return the equipment free of charge.
                                    </li>
                                    <br/>
                                    <li>
                                        5. Since it is not possible to estimate the repairing time before the process has started,
                                        the repair completion time will be announced by Opal Hydraulic subsequently.
                                    </li>
                                    <br/>
                                    <li>
                                        6. Opal Hydraulic is responsible for the collection and the delivery of repairable items.
                                        Within a distance radius of ten kilometres this service is provided free of charge.
                                    </li>
                                </ol>
                            </p>
                        </Col>
                    </Row>
                </Container>
            </div>

            {/* Contact Us Section Start  */}
            <div className="section contactus grapping" id="contact">
                <Tdscontact />
            </div>

            {/* Footer  Section Start */}
            <div className="">
                <Tdsfooter/>
            </div>
        </div>
    );
}